.request-status {
    > p {
        display: inline;
    }

    &.waiting {
        color: #f79805;
    }

}

.request-status-icon {
    margin-left: 5px;
}
