@import "assets/styles/base/_variables";

.request-overview {

    .review-status {
        h2, h4 {
            text-align: center;
        }

        h4 {
            color: $text-color;
            font-weight: $font-weight-regular;
            font-size: $font-size-sm;
        }

        &__desc {
            text-align: center;
            font-size: $font-size-xs;
        }

        .buttons {
            margin-top: 70px;
            text-align: center;

            .button-container {
                margin-right: 15px;

                .button-container:last-child {
                    margin-right: 0;
                }
            }
        }

        &__icon {
            font-size: 40px;
            width: 80px;
            height: 80px;
            border-radius: 80px;
            position: relative;
            margin: 70px auto 10px;

            &--accepted {
                color: $brand-success;
                background-color: rgba(28, 208, 145, 0.2);
            }
            &--rejected {
                color: $brand-danger;
                background-color: rgba(255, 77, 104, 0.2);
            }

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &__status {
            font-size: 27px;
            margin-bottom: 70px;
            text-align: center;
            color: $text-gray-dark;
        }

        &__reason {
            color: $text-gray-dark;
            text-align: center;
            font-size: $font-size-sm;
            margin-bottom: 5px;
        }
    }

}
