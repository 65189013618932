@import "assets/styles/base/_variables";

.user-avatar-input-file {

    position: relative;
    font-weight: $font-weight-regular;
    word-break: break-word;
    color: $text-gray-dark;
    margin: 20px 15px;

    span {
        font-size: $font-size-sm;
        font-weight: $font-weight-semi-bold;
        color: $brand-primary;

        i {
            color: $text-gray;
            font-size: 30px;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    input[type=file] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    &.has-error {
        text-align: left;
    }
}

.user-avatar {
    font-weight: $font-weight-light;
    margin: 15px 0;
    text-align: center;

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 100px;
        margin-right: 10px;
    }

    span {
        display: block;
    }
}
