@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.popup-overlay{
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.76);
	width: 100%;
	height: 100%;
	z-index: 1000000;
	display: none;

	&:not(:last-of-type) {
		visibility: hidden;
	}
}

.popup{
    max-width: 520px;
	width: 85%;
	background-color: #FFF;
	padding: 32px;
	position: absolute;
	top: 50%;
    transform: translateY(-50%) translateX(-50%);
	left: 50%;

    -webkit-box-shadow: 0px 10px 13px -7px #000000, 1px -4px 15px -8px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #000000, 1px -4px 15px -8px rgba(0,0,0,0);

    /*@media (min-width: $screen-sm-min) {
        max-width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
        max-width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
        max-width: 1100px
    }*/

	&__header{
		@include clearfix();
		margin-bottom: 30px;

		&__title{
            color: $text-secondary;
			font-size: $font-size-xl;
			font-weight: $font-weight-semi-bold;
			float: left;
			width: 80%;
		}

		&__close{
			float: right;
			text-align: right;
			width: 10%;
			cursor: pointer;
            font-size: 18px;
            color: $text-gray;
            line-height: 22px;
		}

		&__subtitle{
			font-size: $font-size-sm;
			clear: both;
			padding-top: 10px;
            color: $text-gray-dark;
		}
	}

	&__content{
		max-height: 80vh;

        &--overflow-auto {
            overflow-x: hidden;
            overflow-y: auto;
        }

        &__buttons{
            text-align: right;

            .link-style {
                font-weight: $font-weight-semi-bold;
                margin-right: 20px;
            }

            .btn {
                margin: 0;
            }
        }
	}

    .screen .container {
        width: auto;
    }

    iframe {
        width: 100%;
        height: 65vh;
    }

    > ul {
        padding-left: 40px;
    }

    &__responsive {
        @media (min-width: $screen-sm-min) {
            max-width: $container-sm;
        }
        @media (min-width: $screen-md-min) {
            max-width: $container-md;
        }
    }
}
