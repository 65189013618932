@import "variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

html {
    height: 100%
}

body {
    margin: 0;
    font-family: $font-default;
    font-size: $font-size;
    line-height: $line-height;
    color: $text-color;
    font-weight: 400;
    background: $bg-color;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow-y: scroll;
    overflow-x: hidden;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

[hidden],
template,
.hide {
    display: none;
}

b,
strong {
    font-weight: $font-weight-semi-bold;
}

small,
.small {
    font-size: 85%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    border-top: 1px solid lighten($border-color, 20%);
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    line-height: inherit;
    margin: 0;
}

select {
    background-color: #FFFFFF;
}

button {
    overflow: visible;
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

button[disabled],
html input[disabled] {
    cursor: not-allowed;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

fieldset {
    border: 1px solid $border-color;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

a {
    background-color: transparent;
    color: $brand-primary;
    text-decoration: none;
    font-size: $font-size-sm;
    line-height: 27px;

    &:hover,
    &:focus {
        outline: 0;
        color: darken($brand-primary, 15%);
        text-decoration: none;
    }

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    &:active {
        color: $btn-primary-active;
    }

    &:active {
        color: $btn-primary-active;
    }

    &[disabled] {
        color: $btn-primary-disabled;
        cursor: not-allowed;
    }
}

[role="button"] {
    cursor: pointer;
}

figure {
    margin: 0;
}

img {
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

[role="button"] {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: $font-weight-semi-bold;
    color: $brand-secondary;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    font-weight: normal;
    line-height: 1;
    color: $brand-primary;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-bottom: 20px;
}

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
    font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
    font-size: 75%;
}

h1,
.h1 {
    font-size: $font-size-xxxl;
}

h2,
.h2 {
    font-size: $font-size-xxl;
}

h3,
.h3 {
    font-size: $font-size-xl;
}

h4,
.h4 {
    font-size: $font-size;
}

h5,
.h5 {
    font-size: 14px;
}

h6,
.h6 {
    font-size: 12px;
}

p {
    margin: 0 0 10px;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
    margin-bottom: 0;
}

ul.unstyled {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.container {
    max-width: $container-xs;
}
