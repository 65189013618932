@import 'assets/styles/base/_variables';

.badge{
    display: inline-block;
    padding: 4px 20px;
    text-align: center;
    font-size: 11px;
    font-weight: $font-weight-semi-bold;
    color: #FFF;
    border-radius: 16px;
    line-height: normal;
}

.badge-icon {
    border-radius: 20px;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: relative;
    right: -15px;
    text-align: center;
}

.badge-danger {
    background-color: $brand-danger;

    &--outline{
        background-color: #FFF;
        border: 1px solid $brand-danger;
        color: $brand-danger;
    }

    &--icon {
        background: $brand-danger;
        color: #FFF;
    }

    &.badge-lighter{
        background-color: #ffdbe1;
    }

    &--inverted {
        background-color: #ffdbe1;
        color: $brand-danger;
    }
}
.badge-primary {
    background-color: $brand-primary;

    &.badge-lighter{
        background-color: lighten($brand-primary, 7%);
    }

    &--inverted {
        background-color: #FFF;
        color: $brand-primary;
        box-shadow: 0 0 0 1px $brand-primary;
    }
}
.badge-warning {
    background-color: $brand-warning;

    &.badge-lighter{
        background-color: lighten($brand-warning, 7%);
    }

    &--inverted {
        background-color: lighten($brand-warning, 36%);
        color: $brand-warning;
    }
}
.badge-success {
    background-color: $brand-success;

    &--outline{
        background-color: #FFF;
        border: 1px solid $brand-success;
        color: $brand-success;
    }

    &--icon {
        background: $brand-success;
        color: #FFF;
    }

    &.badge-lighter{
        background-color: lighten($brand-success, 7%);
    }

    &--inverted {
        background-color: lighten($brand-success, 44%);
        color: $brand-success;
    }
}
.badge-warning-light {
    background-color: $brand-warning-light;

    &.badge-lighter{
        background-color: lighten($brand-warning-light, 7%);
    }

    &--inverted {
        background-color: lighten($brand-warning-light, 39%);
        color: #f4b200;
    }
}

.badge-default {
    background-color: $brand-gray-light;
    color: $brand-gray-dark;

    &--outline {
        border: 1px solid $brand-gray-dark;
    }

    &--inverted {
        background-color: $brand-gray-light;
        color: $brand-gray-dark;
    }
}
