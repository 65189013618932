@import 'assets/styles/base/_variables';

.licence-plate {
    position: relative;

    label {
        margin: 0 0 6px 0;
        display: block;
    }

    .licence-plate-input {

        > .form-group.licence-plate-group {
            float: left;
            margin-top: 0;
            margin-bottom: 0;
        }

        > .form-group {

            &.licence-plate-group {
                &__canton {
                    width: 50%;

                    input {
                        text-transform: uppercase;
                        border-right: none;
                        border-radius: $border-radius 0 0 $border-radius;
                        padding-left: 55px;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -2px;
                        width: 4px;
                        height: 4px;
                        transform: translateY(-50%);
                        z-index: 1;
                        background-color: $brand-gray;
                        border-radius: 4px;
                    }
                }
            }

        }

        > .form-group.licence-plate-group {
            &__number {
                width: 50%;

                input {
                    border-left: none;
                    border-radius: 0 $border-radius $border-radius 0;
                    padding-left: 30px;
                }
            }
        }

        &:hover {
            input {
                border: $form-input-hover-border;
            }
        }

        &--focus {
            input:not(.input-error) {
                border: $form-input-focus-border;
            }
        }
    }

    .empty-canton-flag {
        width: 20px;
        height: 20px;
        background-color: #A82D2D;
        line-height: 20px;
        text-align: center;
        color: #FFF;
        font-weight: $font-weight-semi-bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
    }
}
