@import 'assets/styles/base/_variables';

.car-data-complete{
    border-radius: 4px;
    box-shadow: 0 0 12px #bdc0c9;
    padding: 15px;

    &__logo{
        width: 35px;
        height: 35px;
        position: relative;
        margin: 5px;

        img{
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            left: 50%;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__info{
        font-size: $font-size-sm;
        color: $text-color;

        &--make {
            font-weight: $font-weight-semi-bold;
        }
        &--range {}
        &--type {
            color: $text-gray-dark;
        }
    }
}
