@font-face {
    font-family: 'icomoon';
    src: url('/static/fonts/icomoon.eot?y8vfr1');
    src: url('/static/fonts/icomoon.eot?y8vfr1#iefix') format('embedded-opentype'),
    url('/static/fonts/icomoon.ttf?y8vfr1') format('truetype'),
    url('/static/fonts/icomoon.woff?y8vfr1') format('woff'),
    url('/static/fonts/icomoon.svg?y8vfr1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
    content: "\e900";
}

.icon-forward-last:before {
    content: "\e901";
}

.icon-previous-first:before {
    content: "\e902";
}

.icon-archive:before {
    content: "\e903";
}

.icon-car:before {
    content: "\e904";
}

.icon-clock:before {
    content: "\e905";
}

.icon-close:before {
    content: "\e906";
}

.icon-delete:before {
    content: "\e907";
}

.icon-down:before {
    content: "\e908";
}

.icon-download:before {
    content: "\e909";
}

.icon-edit:before {
    content: "\e90a";
}

.icon-export:before {
    content: "\e90b";
}

.icon-eye:before {
    content: "\e90c";
}

.icon-fail:before {
    content: "\e90d";
}

.icon-gear:before {
    content: "\e90e";
}

.icon-left:before {
    content: "\e910";
}

.icon-list:before {
    content: "\e911";
}

.icon-logout:before {
    content: "\e912";
}

.icon-menu:before {
    content: "\e913";
}

.icon-notification:before {
    content: "\e914";
}

.icon-oil:before {
    content: "\e915";
}

.icon-right:before {
    content: "\e916";
}

.icon-search:before {
    content: "\e917";
}

.icon-send:before {
    content: "\e918";
}

.icon-service:before {
    content: "\e919";
}

.icon-settings:before {
    content: "\e91a";
}

.icon-success:before {
    content: "\e91b";
}

.icon-up:before {
    content: "\e91c";
}

.icon-user:before {
    content: "\e91d";
}

.icon-add:before {
    content: "\e91e";
}

.icon-checkbox:before {
    content: "\e91f";
}

.icon-untitled:before {
    content: "\e920";
}

.icon-untitled1:before {
    content: "\e921";
}

.icon-untitled2:before {
    content: "\e922";
}

.icon-untitled3:before {
    content: "\e923";
}

.icon-untitled4:before {
    content: "\e924";
}

.icon-untitled5:before {
    content: "\e925";
}

.icon-untitled6:before {
    content: "\e926";
}

.icon-untitled7:before {
    content: "\e927";
}
