@import 'assets/styles/base/_variables';
@import 'assets/styles/base/_mixins';

.navigation {
    background-color: #fff;
    z-index: 3;
    width: 100%;
    position: fixed;
    font-weight: $font-weight-regular;
    top: 0;
    left: 0;
    border-bottom: 1px solid $brand-gray-light;
    transition: all .2s;

    > .container {
        padding: 0;

        @media (min-width: $screen-sm-min) {
            max-width: $container-sm;
        }
        @media (min-width: $screen-md-min) {
            max-width: $container-md;
        }
        @media (min-width: $screen-lg-min) {
            max-width: 1100px
        }

        @media (min-width: $screen-lg-min) {
            height: 100%;
        }
    }

    &.invisible {
        top: -100px
    }
}

.navigation-login {
    border-bottom: none;
}

.navigation-header {
    height: $navigation-header-height;
    padding: 0 10px;

    &__title {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        float: left;
        color: #435f71;
        font-size: $font-size-xxl;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__icon {
        z-index: 100;
        color: $text-gray-dark;
        font-size: $font-size-xxl;
        cursor: pointer;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        > span {
            z-index: 20;
            float: right;
            margin: 0 20px;
            position: relative;

            &:first-child {
                margin-right: 0;
            }

            &:last-child {
                margin-left: 0;
            }

            .number {
                font-family: $font-default;
                position: absolute;
                top: -5px;
                left: -5px;
                background: $brand-danger;
                color: #fff;
                border-radius: 50px;
                font-size: $font-size-xs;
                width: 15px;
                height: 15px;
                text-align: center;
                line-height: 16px;
            }
        }

        &--settings {
            color: $text-gray-dark;

            .active {
                color: $text-color;
            }
        }
    }
}

.navigation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.76);
    width: 100%;
    height: 100%;
    z-index: 100;
}

.navigation-container {
    background-color: #fff;
    min-width: 190px;
    position: fixed;
    height: 100vh;
    top: 60px;
    right: 0;
    text-align: left;

    &__content {
        cursor: auto;
        padding: 20px;

        &:before {
            content: '';
            width: 100%;
            height: 100vh;
            position: absolute;
            top: -150px;
            left: 0;
            background: #fff;
            z-index: 0;
        }

        &:after {
            content: '';
            background: #fff;
            width: 1000000px;
            position: fixed;
            height: 100vh;
            top: -200%;
            left: 100%;
        }

        &__item {
            z-index: 10;
            color: $text-color;
            display: block;
            font-size: 14px;
            margin-bottom: 20px;
            line-height: normal;


            i {
                font-size: 16px;
                vertical-align: text-top;
                margin-right: 10px;
                width: 20px;
                display: inline-block;
                text-align: center;
            }

            a {
                font-size: 16px;
                vertical-align: text-top;
                line-height: normal;
            }

            &.pick-garage-name {
                margin-bottom: 10px;
            }

            &.pick-garage-link {
                color: $brand-primary;
                font-weight: $font-weight-semi-bold;
            }

            &:last-child {
                margin: 0;
            }
        }

        &__border {
            border-left: 1px solid #e2e4e7;
        }
    }

    &--notifications {
        width: calc(100% - 30px);
        max-height: 300px;
        overflow-y: auto;
    }
}

.navigation-user {
    &__icon {
        font-size: 32px;
        float: left;
    }

    &__data {
        float: left;
        margin-left: 10px;

        div {
            line-height: normal;
            font-size: 12px;
            font-weight: 400;

            &:last-child {
                font-weight: 300;
            }
        }
    }
}
