@import "assets/styles/base/_variables";

.link-icon {

    display: inline-block;

    .link-style {
        i {
            font-size: 16px;
            transform: translateY(2px);
            display: inline-block;

            &.left {
                margin-right: 7px;
            }
            &.right {
                margin-left: 7px;
            }
        }
    }

    &.primary {
        .link-style {
            color: $brand-primary;
        }
    }
    &.danger {
        .link-style {
            color: $brand-danger;
        }
    }
}
