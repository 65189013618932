@import "variables";

.form-group {
    position: relative;
    margin: $form-margin;
    text-align: left;

    label {
        word-wrap: break-word;
        display: inline-block;
        color: $form-input-label-color;
        font-size: $form-input-label-size;
        margin-left: 0;
        margin-bottom: 6px;
    }

    &.disabled {
        label {
            color: $form-input-disabled-color-label;
        }
    }
}

form {
    @include clearfix;
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea,
select::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[readonly]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
    font-weight: $font-weight-regular;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea {
    display: block;
    width: 100%;
    border-radius: $border-radius;
    border: $form-input-border;
    background-color: $form-input-background;
    min-height: $form-input-min-height;
    height: auto;
    line-height: $form-input-line-height;
    padding: $form-input-padding;
    outline: 0;
    box-shadow: none;

    &[disabled] {
        background-color: $form-input-disabled-background;
        color: $brand-gray;
        border: $form-input-disabled-border;
    }

    &[disabled] + label {
        color: $form-input-disabled-color-label;
    }

    &[readonly] {
        cursor: default;
    }

    &::placeholder {
        color: $form-input-placeholder;
    }

    &:hover{
        &:not(:disabled){
            border: $form-input-hover-border;

            &::placeholder {
                color: $text-gray-dark;
            }
        }
    }

    &:active, &:focus {
        color: $text-color;

        &:not(:disabled) {
            border: $form-input-focus-border;
        }
    }
}

textarea {
    display: block;
    width: 100%;
}

input.input-error,
textarea.input-error {
    border: 1px solid $brand-danger;
    background-color: $form-error-background;

    & + label {
        color: $brand-danger;
    }

    &:active, &:focus {
        &:not(:disabled) {
            border: 1px solid $brand-danger;
        }
    }
}

input[readonly] {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    user-select: none;

    &:focus {
        border: 0 !important;
    }
}

/*
input[type=file] {
    display: none;
}
*/

.form-group {

    select {
        padding: 20px 20px 10px 20px;
        -webkit-appearance: none;
        background-image: url($select-icon);
        background-size: 10px;
        background-position: calc(100% - 15px) center;
        background-repeat: no-repeat;
    }
}

.has-error {
    font-size: 12px;
    color: $brand-danger;
    margin-top: 0;
    display: inline-block;
    position: absolute;
    text-align: right;
    width: 100%;
}

.has-success {
    font-size: 12px;
    color: $brand-success;
    margin-top: 0;
    display: inline-block;
    position: absolute;
    text-align: right;
    width: 100%;
}


.success-text {
    color: $brand-success;
}

.input-group-addon {
    position: absolute;
    right: 0;
    text-align: center;
    line-height: 41px;
    padding: 0 15px;
    bottom: 0;
    font-size: $font-size-sm;
    color: $text-gray-dark;

    ~ input {
        padding: 10px 50px 10px 16px;
    }
}

.custom-checkbox {
    position: relative;

    label {
        color: $text-color;
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
        display: inline-block;
        padding-left: 30px;
        line-height: 22px;
        vertical-align: super;
    }
}

.custom-checkbox-label {
    display: inline-block;
    position: absolute;
    top: 0;

    input {
        opacity: 0;
        cursor: pointer;
        z-index: 6;
        position: absolute;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
    }

    .custom-checkbox--radio {
        border: $form-input-border;
        border-radius: 21px;
        padding: 2px;
        width: 21px;
        height: 21px;

        span {
            visibility: hidden;
        }
    }

    .custom-checkbox--checkbox {
        border: $form-input-border;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        margin: 2px;
        position: relative;

        span {
            visibility: hidden;
        }
    }

    input:checked {
        &+.custom-checkbox--radio {
            span {
                visibility: visible;
                background-color: $brand-primary;
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 21px;
            }
        }

        &+.custom-checkbox--checkbox {
            border: none;
            background-color: $brand-primary;
            text-align: center;

            span {
                visibility: visible;
                color: #FFF;
                font-size: 18px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    input:disabled:not(:checked) {
        &+.custom-checkbox--checkbox {
            border: 1px solid $border-color-light;
        }
    }

    input:disabled:checked {
        &+.custom-checkbox--checkbox {
            background-color: $btn-primary-disabled;
        }
    }

    .custom-checkbox--loading {
        border-color: transparent !important;
    }

    input:disabled + span {
        color: $form-input-disabled-color-label;
    }
}
