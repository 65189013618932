@import 'assets/styles/base/_variables';
@import 'assets/styles/base/_mixins';

@media (max-width: $screen-xs-max) {
    .form-step-counter {
        margin-bottom: 40px;
        text-align: center;

        .form-step-item {

            display: inline-block;
            margin: 0 5px;

            &__index {
                margin: 0;
            }

            &__title {
                display: none;
            }

            .active {
                position: relative;
                top: 3px;
            }
        }
    }
}

.form-step-item {
    @include clearfix();
    margin-bottom: 10px;
    font-weight: $font-weight-semi-bold;
    line-height: 30px;

    &__index {
        width: 30px;
        height: 30px;
        text-align: center;
        color: $brand-primary;
        border: 1px solid $brand-primary;
        border-radius: 30px;
        font-size: 15px;
        float: left;
        margin-left: 3.5px;
        line-height: 30px;


        &.active {
            width: 37px;
            height: 37px;
            line-height: 37px;
            background-color: $brand-primary;
            color: #FFF;
            font-size: $font-size-xl;
            margin-left: 0;
        }
    }

    &__title {
        color: $brand-secondary;
        float: inherit;
        line-height: 30px;
        margin-left: 45px;

        &.active {
            font-size: $font-size-xl;
            line-height: 37px;
            margin-left: 45px;
        }
    }
}
