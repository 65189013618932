@import "assets/styles/base/_variables";

.submit-container{
    width: 100%;
    position: fixed;
    bottom: 50px;
    left: 0;
    z-index: 10;

    @media (min-width: $screen-sm-min) {
        bottom: 50px;
    }

    &--sticky{
        position: sticky;
        bottom: 0;
    }

    &--static {
        position: static;
    }

    .container {
        padding-left: calc(25px + 10px);
        padding-right: calc(25px + 10px);
    }

    @media (orientation: landscape) and (max-height: 500px) {
        position: relative;
        bottom: 0;

        .container {
            padding: 0;
        }
    }
}
