@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.overview-step {
    h2 {
        margin-bottom: 30px;
    }

    .labour-detail {
        background: #FFFFFF;
        box-shadow: 0 0 10px #E1E3E6;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 25px;
    }

    .labour-detail-header {
        padding: 8px;
        background: $brand-gray-light;
        border-radius: 4px;
        @include clearfix;

        &__title {
            float: left;
            font-size: $font-size;
            font-weight: $font-weight-semi-bold;
        }

        &__icons {
            float: right;
            font-size: $font-size-xs;
            line-height: 22px;

            span {
                cursor: pointer;

                i {
                    font-size: $font-size;
                    vertical-align: text-top;
                    margin-right: 5px;
                }
            }

            .remove {
                color: $brand-danger;
            }
        }
    }

    .labour-detail-content {
        margin-top: 15px;

        ul {
            padding-left: 24px;
            margin-bottom: 8px;

            li {
                padding-left: 5px;
            }

            ul {
                margin-top: 8px;
            }
        }

        .labour-detail-work {

        }

        .labour-detail-part {
            color: $text-gray-dark;
        }

    }

    .official-services {
        border: 1px solid rgba(86, 79, 231, 0.25);
        box-shadow: 0 0 10px #E1E3E6;
        border-radius: 8px;
        margin-bottom: 25px;
        padding-bottom: 20px;

        .official-services-header {
            @include clearfix;
            margin-bottom: 15px;
            padding: 20px 16px;
            border: 1px solid $border-color;

            &__title {
                float: left;
                font-size: $font-size;
                font-weight: $font-weight-semi-bold;
                color: $text-secondary;
            }

            &__icons {
                float: right;
                font-size: $font-size-xs;
                line-height: 22px;

                span {
                    cursor: pointer;

                    i {
                        font-size: $font-size;
                        vertical-align: text-top;
                        margin-right: 5px;
                    }
                }

                .remove {
                    color: $brand-danger;
                }
            }
        }

        .labour-detail {
            box-shadow: none;
            margin: 0;
            padding-bottom: 0;
        }
    }

    .total-price {
        @include clearfix;
        font-size: $font-size-xxl;
        color: $text-secondary;
        font-weight: $font-weight-semi-bold;

        div:first-child {
            float: left;
        }

        div:last-child {
            float: right;
            text-align: right;
        }
    }

    .total-price.smaller-price {
        font-size: $font-size;
    }

    .request-specification {
        margin-top: 50px;
        margin-bottom: 50px;

        h4 {
            margin-bottom: 25px;
        }

        i {
            margin-right: 5px;
        }

        .spec-item {
            margin-bottom: 30px;
            font-size: $font-size-sm;

            .spec-item-title {
                color: $text-secondary;
                font-weight: $font-weight-semi-bold;
                margin-bottom: 25px;
                font-size: $font-size;
            }

            .spec-item-recommended-oil {
                font-size: $font-size-xs;
                color: $text-gray-dark;

                > * {
                    margin-bottom: 10px;
                }
            }

            .spec-item-header {
                @include clearfix;
                background-color: $brand-gray-light;
                padding: 10px 0;
                color: $text-gray-dark;
                font-weight: $font-weight-semi-bold;
                margin-bottom: 15px;
                border-radius: 4px;

                * {
                    text-align: center;
                }

                *:first-child {
                    text-align: left;
                }

                *:last-child {
                    text-align: right;
                }
            }

            .spec-item-list {
                &__item {
                    @include clearfix;
                    margin-bottom: 8px;
                    font-size: $font-size-xs;

                    * {
                        text-align: center;
                    }

                    *:first-child {
                        text-align: left;
                    }

                    *:last-child {
                        text-align: right;
                    }

                    .currency {
                        font-size: 80%;
                        color: $text-gray-dark;
                    }
                }
            }

            .spec-item-total {
                @include clearfix;
                margin-top: 20px;
                font-weight: $font-weight-semi-bold;

                *:last-child {
                    text-align: right;
                }

                .currency {
                    font-size: 80%;
                    color: $text-gray-dark;
                }
            }
        }

        .spec-total {
            font-weight: $font-weight-semi-bold;
            border-top: 1px solid $border-color;
            padding-top: 30px;

            &__item {
                @include clearfix;
                font-size: $font-size-sm;
                margin-bottom: 8px;

                *:last-child {
                    text-align: right;
                }

                .currency {
                    font-size: 80%;
                    color: $text-gray-dark;
                }
            }

            &--total {
                font-size: $font-size;
            }
        }
    }

}
