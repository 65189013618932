@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.official-services-container {
    h2 {
        margin-bottom: 30px;
    }

    h4 {
        margin-top: 40px;
        margin-bottom: 20px;
    }
}

.official-services-main {
    .official-services-item:last-of-type {
        border-bottom: none;
    }
}

.official-services-additional {
    .official-services-item:last-of-type {
        border-bottom: none;
    }
}

.official-services-single {
    padding: 25px;
    border-radius: $border-radius;
    box-shadow: 0 0 10px #E1E3E6;
    margin-bottom: 20px;

    &__name {
        @include clearfix();

        line-height: normal;
        font-size: $font-size-sm;
        margin-bottom: 5px;
        color: $brand-gray-dark;
        padding-left: 32px;

        &__left {
            float: left;
            width: 70%;

            &__name {
                max-width: 100%;
                float: left;
            }
        }

        &__right {
            float: right;

            .link-style {
                line-height: normal;
                font-size: $font-size;
                color: $text-blue;
                vertical-align: middle;
            }
        }
    }

    &__header {
        margin-bottom: 10px;
        font-size: 14px;
        color: $text-gray-dark;
        border-bottom: 1px solid $brand-gray-light;
        padding-bottom: 10px;
    }

    &__total {
        @include clearfix();

        margin-top: 20px;
        font-size: $font-size;
        color: $text-gray-dark;
        padding-left: 50px;
        border-top: 1px dashed $brand-gray-light;
        padding-top: 10px;
    }

    label {
        color: $text-gray-dark;
        font-size: $font-size;
        font-weight: $font-weight-semi-bold;
        line-height: 24px;
    }

    .custom-checkbox-label .custom-checkbox {
        &--checkbox {
            width: 20px;
            height: 20px;

            .icon-checkbox {
                font-size: 20px;
            }
        }
    }

}

.official-services-single-selected{
    box-shadow: 0 0 10px rgba(0, 32, 109, 0.24);

    label {
        color: $text-color;
    }
}

.official-services-main, .official-services-additional {
    border-radius: $border-radius;
    box-shadow: 0 0 10px #E1E3E6;
    margin-bottom: 20px;

    .official-services-item {
        border-bottom: 1px solid #e1e3e6;
        margin-top: 0;
        padding: 20px;

        &__name {
            @include clearfix();

            font-size: $font-size;
            margin-bottom: 0;
            color: $text-gray-dark;
            line-height: normal;

            .link-style {
                line-height: normal;
                font-size: $font-size;
                color: $text-blue;
                vertical-align: middle;
                position: relative;
                top: 3px;
            }

            &__left {
                float: left;
                width: 70%;
                color: $text-color;

                &__name {
                    max-width: 70%;
                }
            }

            &__add-text {
                clear: both;
                color: $text-gray-dark;
                font-size: $font-size-sm;
            }

            &__description {
                margin-top: 6px;
            }

            .service-in-official {
                font-size: 10px;
                color: $brand-success;
                margin-right: 10px;

                i {
                    margin-top: -2px;
                    transform: scale(1.6);
                    display: inline-block;
                    margin-right: 5px;
                    vertical-align: middle;
                }
            }
        }

        .custom-control-label {
            display: block;
        }
    }
}
