@import "assets/styles/base/_variables";

.form-container {

    &__header {
        padding-bottom: 10px;
    }

    .licence-plate-form-group {
        width: 100%;
        max-width: 215px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .link-style {
        margin: 15px 0;
        display: inline-block;
    }
}
