@import 'assets/styles/base/_variables';

.toast-custom{

    position: relative;

    &__icon{
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 32px;
    }
}

.toast-custom-content{
    padding-left: 50px;
    font-family: $font-default;

    &__title{
        font-weight: $font-weight-semi-bold;
        margin-bottom: 6px;
        font-size: 14px;
    }

    &__text{
        color: $text-gray-dark;
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
    }
}
