@import 'assets/styles/base/_variables';

.car-number{
    margin-bottom: 15px;
    position: relative;
}

.car-number-input{
    width: 100%;
    max-width: 300px;
    margin: auto;
    position: relative;
}

.car-number-form-group{
    width: 100%;
    max-width: 300px;
    margin: auto;
    position: relative;
}
