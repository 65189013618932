@import "assets/styles/base/_variables";

.select2__control.css-yk16xz-control{
    &:hover{
        border-color: $brand-gray-light;
        color: $text-gray;
    }
}

.select2__control.select2__control--is-focused{
    &:hover{
        border-color: $brand-primary;
    }
}

.select2__control.css-yk16xz-control, .select2__control.select2__control--is-focused {

    height: 41px;
    border-radius: $border-radius;
    border: $form-input-border;
    border-color: $brand-gray;
    box-shadow: none;
    cursor: pointer;

    &:hover{
        color: $text-gray;
    }

    input{
        min-height: auto;
        position: relative;
        left: -3px;
        border-right: 0;
    }

    .css-1uccc91-singleValue{
        color: $text-color;
        margin: 0;
    }

    .css-g1d714-ValueContainer{
        padding-left: 16px;
    }

    .css-1okebmr-indicatorSeparator{
        display: none;
    }
}

.select2__control.select2__control--is-focused{
    border: $form-input-focus-border;
}

/*.select2__control{
    .select2__indicators{
        display: none;
    }
}*/

.select2__option{
    background-color: #FFF !important;

    &--is-focused{
        font-weight: $font-weight-semi-bold;
    }
}
