.spinner {
    margin: 100px auto 0;
    width: 100%;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #000;
    margin: 0 3px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
