.Toastify__toast-container.booster-toast-container{
    padding: 10px;
    z-index: 9999999;

    &--bottom-center{
        padding: 20px;
    }

    .Toastify__toast{

        min-height: auto;
        padding: 15px;
        border-radius: 5px;

        margin-bottom: 10px;

        &:last-child{
            margin-bottom: 0;
        }

        &--default{
            background-color: rgb(50, 50, 50);
            color: #FFF;
        }

    }

    .Toastify__close-button{
        opacity: 1;
        &--default{
            color: #FFF;
        }
    }

    .Toastify__toast--success{
        background-color: #FFF;
        color: $brand-success;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    }

    .Toastify__toast--error{
        background-color: #FFF;
        color: $brand-danger;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    }

    .Toastify__toast--info{
        background-color: #FFF;
        color: $brand-primary;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    }

    .Toastify__toast--warning{
        background-color: #FFF;
        color: $brand-warning;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
    }
}
