@import 'assets/styles/base/_variables';

.footer {
    bottom: 0;
    position: inherit;
    font-size: 10px;
    font-weight: 400;
    font-family: "Open Sans", serif;
    color: $brand-gray;
    width: 100%;
    line-height: 14px;
    padding-bottom: 20px;
}

.footer__left {
    float: left;
    text-align: left;
}

.footer__right {
    float: right;
    text-align: right;
}

.footer__margin-left-24 {
    margin-left: 24px;
}

.footer__clickable {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .footer .footer__left {
        width: 100%;
        text-align: center;
        float: none;
        padding-bottom: 20px;
    }
    .footer .footer__right {
        width: 100%;
        text-align: center;
        float: none;
        padding-bottom: 20px;
    }
    .footer .footer__clickable {
        width: 100%;
        display: block;
        padding-bottom: 20px;
    }
    .footer .footer__margin-left-24 {
        margin-left: 0px;
    }
}
