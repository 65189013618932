.review-status.in-progress {
    span {
        margin-left: 40px;
    }

    span:first-of-type {
        margin-left: 0;
    }
}

@media only screen and (max-width: 768px) {
    .review-status.in-progress {

        text-align: center;

        span {
            float: none;
            display: block;
            margin: 20px 0 0;
        }

        span:first-of-type {
            float: none;
            display: block;
            margin: 0;
        }
    }
}
