@mixin clearfix() {
    &:after {
        content: '';
        clear: both;
        display: block;
    }
}

@mixin button-bg($bg-color, $color, $outline-color: 'none') {
    $bg-color-hover: lighten($bg-color, 10%);
    background-color: $bg-color;
    color: $color;

    // if outline color is defined & is not default (none)
    @if ($outline-color != 'none') {
        box-shadow: inset 0 0 0 2px $outline-color;
    }

    &:active,
    &:focus {
        color: $color;
        background-color: $bg-color;
    }

    &[disabled] {
        box-shadow: none;
        background-color: $btn-disabled;
        color: #fff;

        // if outline color is defined & is not default (none)
        @if ($outline-color != 'none') {
            box-shadow: inset 0 0 0 2px $btn-disabled;
        }
    }

    @media (min-width: $screen-md-min) {
        &:hover {
            background-color: $bg-color-hover;
            color: $color;

            // if outline color is defined & is not default (none)
            @if ($outline-color != 'none') {
                box-shadow: inset 0 0 0 2px $outline-color;
                color: $bg-color;
                background-color: $color;
            }
        }
        &:disabled:hover {
            //background-color: inherit;
        }
    }
}

@mixin container-fixed($gutter: $padding-grid-columns) {
    margin-right: auto;
    margin-left: auto;
    padding-left: floor(($gutter / 2));
    padding-right: ceil(($gutter / 2));
    @include clearfix;
}

@mixin make-row($gutter: $padding-grid-columns) {
    margin-left: ceil(($gutter / -2));
    margin-right: floor(($gutter / -2));
    @include clearfix;
}

// Grid generator function
@mixin make-grid-columns($i: 1, $list: '.col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}') {
    @for $i from (1 + 1) through $grid-columns {
        $list: '#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}';
    }
    #{$list} {
        position: relative;
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-left: ceil(($padding-grid-columns / 2));
        padding-right: floor(($padding-grid-columns / 2));
    }
}

@mixin float-grid-columns($class, $i: 1, $list: '.col-#{$class}-#{$i}') {
    @for $i from (1 + 1) through $grid-columns {
        $list: '#{$list}, .col-#{$class}-#{$i}';
    }
    #{$list} {
        float: left;
    }
}

@mixin calc-grid-column($index, $class, $type) {
    @if ($type == width) and ($index > 0) {
        .col-#{$class}-#{$index} {
            width: percentage(($index / $grid-columns));
        }
    }
    @if ($type == push) and ($index > 0) {
        .col-#{$class}-push-#{$index} {
            left: percentage(($index / $grid-columns));
        }
    }
    @if ($type == push) and ($index == 0) {
        .col-#{$class}-push-0 {
            left: auto;
        }
    }
    @if ($type == pull) and ($index > 0) {
        .col-#{$class}-pull-#{$index} {
            right: percentage(($index / $grid-columns));
        }
    }
    @if ($type == pull) and ($index == 0) {
        .col-#{$class}-pull-0 {
            right: auto;
        }
    }
    @if ($type == offset) {
        .col-#{$class}-offset-#{$index} {
            margin-left: percentage(($index / $grid-columns));
        }
    }
}

@mixin loop-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-grid-column($i, $class, $type);
    }
}

@mixin make-grid($class) {
    @include float-grid-columns($class);
    @include loop-grid-columns($grid-columns, $class, width);
    @include loop-grid-columns($grid-columns, $class, pull);
    @include loop-grid-columns($grid-columns, $class, push);
    @include loop-grid-columns($grid-columns, $class, offset);
}
