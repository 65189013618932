.pick-garage-item {
  color: $text-gray-dark;
  text-align: center;
  font-size: $font-size-xl;
  background-color: rgba(143, 156, 165, 0.16);
  border-radius: 8px;
  margin-bottom: 10px;
  display: block;
  padding: 35px 10px;
  cursor: pointer;

  &:hover{
      background-color: rgba(143, 156, 165, 0.05);
  }

  &__name{
      font-weight: $font-weight-semi-bold;
  }

  &__address{
      font-weight: $font-weight-light;
  }
}
