@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.horizontal-elements{
	margin: 10px 0;
	@include clearfix();

	&__item {
		float: left;
		padding: 10px 15px;
		border: 1px solid lighten($brand-primary, 30%);
		margin: 3px 3px;
		border-radius: 100px;
		font-weight: $font-weight-semi-bold;
		color: #009eff;
		font-size: $font-size-xs;
		line-height: normal;
		cursor: pointer;

		&--active{
			background-color: $brand-primary;
			color: #FFF;
		}
	}
}