@import 'assets/styles/base/_variables';

.drop-down-list {
    border-radius: 8px;
    min-width: 125px;
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #FFF;
    border: $form-input-border;
    transform: translate(-50%, calc(100% + 5px));
}

.drop-down-item {
    text-align: center;
    font-size: $font-size-sm;
    padding: 8px 10px;
    cursor: pointer;

    border-bottom: 1px solid $border-color;

    &:hover {
        background-color: #F8F9FA;
    }
    &:active {
        background-color: #F1F2F6;
    }

    &:first-child {
        border-radius: 8px 8px 0 0;
    }
    &:last-child {
        border: none;
        border-radius: 0 0 8px 8px;
    }
}
