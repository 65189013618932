@import "assets/styles/base/_variables";

input {
    &.has-left-icon {
        padding-left: 42px;
    }

    &.has-right-icon {
        cursor: pointer;
        padding-right: 42px;
    }
}

.input-icon {
    bottom: 0;
    position: absolute;
    height: 40px;
    width: 40px;
    text-align: center;

    &--left {
        left: 0;
        border-radius: $border-radius 0 0 $border-radius;
        color: $text-gray-dark;

        &--border{
            border-right: 1px solid $border-color;
        }
    }

    &--right {
        cursor: pointer;
        right: 0;
        border-radius: 0 $border-radius $border-radius 0;
        color: $text-gray-dark;

        &--border{
            border-left: 1px solid $border-color;
        }
    }

    > span {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: 0 auto;
        left: 0;
        right: 0;
        font-size: $font-size-sm;
    }

    > img {
        width: 16px;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}

.form-error {
    display: inline-block;
    width: 100%;
    text-align: left;

    > p {
        margin: 6px 0 0 16px;
        font-size: $font-size-xs;
        color: $brand-danger;
    }
}
