.logo {

    text-align: center;

    img {
        max-height: 40px;
    }

    a {
        float: left;
    }

    .amag-logo {
        max-width: 220px;
        margin-left: 20px;
        margin-top: 8px;
    }
}
