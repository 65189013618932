@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.labours-step {
    h2 {
        margin-bottom: 30px;
    }

    .labours-container {

        box-shadow: 0 0 10px #E1E3E6;
        border-radius: $border-radius;

        .labour-search {
            padding: 15px;

            .form-group {
                margin: 0;
            }
        }
    }
}

.labours-list, .tire-labours-list {

    .labour-item {
        padding: 20px 15px 15px;
        border-top: 1px solid $border-color;

        .name {
            font-size: $font-size;
            line-height: 20px;
        }

        .desc {
            font-size: $font-size-xs;
            color: $text-gray-dark;
            line-height: 20px;
            margin-top: 10px;
        }

        .service-in-official {
            font-size: 10px;
            color: $brand-success;
            margin-right: 10px;

            i {
                margin-top: -2px;
                transform: scale(1.6);
                display: inline-block;
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        .service-not-available {
            font-size: 10px;
            color: $brand-danger;
            margin-right: 10px;

            i {
                margin-top: -2px;
                transform: scale(1.6);
                display: inline-block;
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        &--load-more {
            font-size: $font-size-sm;
            color: $text-gray-dark;
            cursor: pointer;
            text-align: center;

            &:hover {
                background-color: $brand-gray-light;
            }
        }

        &--no-result {
            font-style: italic;
        }

        &--not-available {
            .name {
                color: $text-gray;
            }

            .desc {
                color: $text-gray-light;
            }
        }

        &__siblings {
            &__name {
                margin: 5px 0 5px 30px;
                font-size: $font-size;
                line-height: 20px;
                color: $text-gray-dark;
            }
        }
    }
}
